export default async function imageToString(file, maxWidth = 1024) {
  const loadImage = await import('blueimp-load-image')
  const { image } = await loadImage.default(file, {
    maxWidth,
    orientation: true,
    canvas: true
  })
  const string = image.toDataURL('image/jpeg', 0.85)
  const { width, height } = image

  return { width, height, string }
}
