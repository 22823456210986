const state = {
  auth: false,
  phoneUpdate: false,
  createCreditCard: false,
  paymentMethod: false,
  contactUs: false,
  singlePhoto: false,
  opening: [],
  openingKey: {},
  params: {}
}

export default function usePanel() {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('hooks/panel/index', uuid)

  const open = (panel, params) => {
    state[panel] = true
    state.params[panel] = params
    mutate()
    if (panel !== 'auth') return
    mkt.authOpen()
  }

  const close = panel => {
    state[panel] = false
    delete state.params[panel]
    mutate()
  }

  const params = panel => state.params[panel]

  const updateParams = (panel, key, value) => {
    state.params[panel][key] = value
    mutate()
  }

  const visible = panel => state[panel]

  const toggle = panel => {
    state[panel] = !state[panel]
    mutate()
  }

  const zIndexResolver = (key, visible) => {
    if (visible) {
      if (!state.openingKey[key]) {
        state.opening.push(key)
        state.openingKey[key] = true
      }
      let index = state.opening.indexOf(key)
      return 100 + index
    }
    if (!state.openingKey[key]) return ''
    state.opening = state.opening.filter(current => current != key)
    state.openingKey[key] = false
    return ''
  }

  return {
    state,
    open,
    close,
    params,
    updateParams,
    visible,
    toggle,
    zIndexResolver
  }
}
