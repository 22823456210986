import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://examplePublicKey@o0.ingest.sentry.io/0',
  ignoreErrors: ['Network Error', 'Network request failed', 'Permission denied', 'Load failed'],
  beforeSend(event, hint) {
    const error = hint.originalException
    const shouldSkip = () => {
      if (!error) return false
      if (error.styleMapError) return true
      const message = error.message || ''
      if (message.includes('network error')) return true
      if (message.includes('Failed to fetch')) return true
      if (message.includes('Network Error')) return true
      if (message.includes('Client is offline')) return true
      if (message.includes('已取消')) return true
      if (message.includes('cancelled')) return true
      if (message.includes('Key already exists in the object store')) return true
      if (message.includes('502 Bad Gateway')) return true
      if (message.includes('Loading chunk')) return true
      if (message.includes('hasOwnProperty')) return true
      if (message.includes('_AutofillCallbackHandler')) return true
      return false
    }
    if (shouldSkip()) return
    return event
  },
  tracesSampleRate: 1.0
})
