export default function Statistic() {
  return (
    <svg width='30' height='28' viewBox='0 0 30 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 27.0134H29' stroke='#ADADAD' strokeWidth='0.5' strokeLinecap='round'/>
      <path d='M1 21.7222H29Z' fill='#EEEEEE'/>
      <path d='M1 21.7222H29' stroke='#E1E1E1' strokeWidth='0.5'/>
      <path d='M1 16.431H29Z' fill='#EEEEEE'/>
      <path d='M1 16.431H29' stroke='#E1E1E1' strokeWidth='0.5'/>
      <path d='M1 11.1397H29Z' fill='#EEEEEE'/>
      <path d='M1 11.1397H29' stroke='#E1E1E1' strokeWidth='0.5'/>
      <path d='M1 5.84857H29Z' fill='#EEEEEE'/>
      <path d='M1 5.84857H29' stroke='#E1E1E1' strokeWidth='0.5'/>
      <path d='M1 0.557373H29Z' fill='#EEEEEE'/>
      <path d='M1 0.557373H29' stroke='#E1E1E1' strokeWidth='0.5'/>
      <path d='M3.87305 27.1901V28' stroke='#ADADAD'/>
      <path d='M9.46875 27.1901V28' stroke='#ADADAD'/>
      <path d='M15.0664 27.1901V28' stroke='#ADADAD'/>
      <path d='M20.6621 27.1901V28' stroke='#ADADAD'/>
      <path d='M26.2598 27.1901V28' stroke='#ADADAD'/>
      <path d='M1.86914 26.9472H5.73314V21.7089H1.86914V26.9472Z' fill='#BAA483'/>
      <path d='M7.46875 26.9472H11.3327V16.4177H7.46875V26.9472Z' fill='#BAA483'/>
      <path d='M13.0684 26.9472H16.9324V11.1265H13.0684V26.9472Z' fill='#BAA483'/>
      <path d='M18.666 26.9472H22.53V5.83533H18.666V26.9472Z' fill='#BAA483'/>
      <path d='M24.2676 26.9471H28.1316V0.491211H24.2676V26.9471Z' fill='#BAA483'/>
      <path d='M18 0L15.2051 0.722443L17.2282 2.78167L18 0ZM1.67521 16.3889L16.5702 1.75518L16.2198 1.39852L1.32479 16.0322L1.67521 16.3889Z' fill='#BAA483'/>
    </svg>

  )
}
