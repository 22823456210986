const keyDic = [
  ['一.', '二.', '三.', '四.', '五.', '六.', '七.', '八.', '九.', '十.', '十一.', '十二.', '十三.', '十四.', '十五.', '十六.'],
  [],
  [],
  ['A.', 'B.', 'C.', 'D.', 'E.', 'F.', 'G.'],
  ['i.', 'ii.', 'iii.']
]
export default function Li({ text, level, number }) {
  level = parseInt(level)
  number = parseInt(number)

  const fetchKey = () => {
    switch (level) {
      case 1:
      case 4:
      case 5:
        return keyDic[level - 1][number - 1]
      case 3:
        return `(${number})`
      default:
        return number + '.'
    }

  }
  const key = fetchKey()

  const color = () => {
    if (level === 1) return 'text-business'
  }

  const padding = () => {
    if (level === 1) return 'py-3'
    if (level === 2) return 'py-1'
  }

  const size = () => {
    if (level === 1) return 'text-[1.1rem]'
  }

  const keyStyle = () => {
    if (level === 5) return 'w-5'
  }

  return (
    <div className={ cn('flex', color(), padding(), size()) }>
      <div className={ cn('mr-1 text-right shrink-0', keyStyle()) }>{ key }</div>
      { text }
    </div>
  )
}
