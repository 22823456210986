const inject = (form, field) => {
  const fields = field ? [field] : Object.keys(form)
  let onceError = false
  fields.forEach(field => {
    if (typeof form[field] != 'object') return
    const error = errorResolver(form, field)
    form[field].error = error
    if (!error) return
    onceError ||= form[field]
  })
  return onceError
}

export { inject }

const errorResolver = (form, prop) => {
  const { rule, value } = form[prop]

  if (!rule) return
  const rules = rule.split('|')
  for (const current of rules) {
    switch (current) {
      case 'required':
        const isString = typeof value === 'string'
        const isNumber = typeof value === 'number'
        if (value && !isString) continue
        if (isString && value.trim()) continue
        if (isNumber) continue
        return ['validator.required']
      case 'notZero':
        if (value !== 0) continue
        return ['validator.isZero']
      case 'greaterThanZero':
        if (value > 0) continue
        return ['validator.lowerThanZero']
      case 'email':
        if (!value) continue
        if (value.isEmail()) continue
        return ['validator.email']
      case 'onlyDigit':
        if (!value) continue
        if (/^\d+$/.test(value)) continue
        return ['validator.onlyDigit']
      case 'monthYear':
        if (!value) continue
        if (/^[\d]{2}\s*\/\s*[\d]{2}$/.test(value)) continue
        return ['validator.monthYear']
      default:
        const [name, param] = current.split(':')
        switch (name) {
          case 'max':
            const maxCount = parseInt(param)
            if (value.length <= maxCount) continue
            return ['validator.max', { maxCount }]
          case 'min':
            const minCount = parseInt(param)
            if (value.length >= minCount) continue
            return ['validator.min', { minCount }]
          case 'confirm':
            if (value === form[param].value) continue
            return ['validator.confirm']
          default:
            throw Error('bad validator rule')
        }
    }
  }
}
