export default function seo({ title, description, canonical }) {
  const renderTitle = () => {
    if (!title) return []
    return [
      <title key='title'>{ title }</title>,
      <meta property='og:title' content={ title } key='og:title' />
    ]
  }
  const renderDescription = () => {
    if (!description) return []
    const desc = description.replace(/<\//g, '<\\/').replace(/\s+/g, ' ')
    return [
      <meta name='description' content={ desc } key='description' />,
      <meta property='og:description' content={ desc } key='og:description' />
    ]
  }
  const renderCanonical = () => {
    if (!canonical) return
    return (
      <link rel='canonical' href={ canonical } key='canonical'/>
    )
  }

  return [
    ...renderTitle(),
    ...renderDescription(),
    renderCanonical()
  ]
}
