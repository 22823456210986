const template = `
  query bindedCards {
    me {
      mapPayCard
      mapPayRewards
    }
    cards {
      id
      cardType
      last4
      bankName
      bankNameTw
    }
    stylePayConfig {
      zeroCardFeeRate {
        installment
        rate
      }
    }
  }
`

export default async function config() {
  const { cards, me, stylePayConfig: { zeroCardFeeRate } } = await request(template)
  const zeroCardFeeRateDic = zeroCardFeeRate.reduce((dic, feeRate) => {
    dic[feeRate.installment] = feeRate.rate
    return dic
  }, {})
  return { cards, zeroCardFeeRateDic, ...me }
}
