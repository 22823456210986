const template = `
  query Notifications($limit: Int!, $offset: Int!) {
    notifications(limit: $limit, offset: $offset) {
      id
      message
      isRead
      createdAt
      notifierUser {
        id
        name
        avatar
      }
      actionType
      actionId
      actionUrl
      photoUrls
    }
  }
`

export default async function query({ limit, offset }) {
  const { notifications } = await request(template, { limit, offset })
  return notifications
}