const template = `
  mutation SwitchToUser($id: ID!) {
    switchToUser(id: $id) {
      user {
        name
      }
    }
  }
`

export default async function switchAccount(id) {
  const { switchToUser } = await request(template, { id })
  return switchToUser.user
}
