export default function useTrackPageScroll() {
  useEffect(() => {
    let count = 0
    const interval = setInterval(() => {
      const doc = window.document.documentElement
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      count += 1

      mkt.trackPageScroll(top)
      if (count >= 20) cleanInterval()
    }, 1000)

    const cleanInterval = () => {
      clearInterval(interval)
    }

    return cleanInterval
  }, [])
}
