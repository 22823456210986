const template = `
  mutation VisitorLogCreate($input: VisitorLogCreateInput) {
    visitorLogCreate(input: $input)
  }
`

export default async function visitorLogCreate(input) {
  const path = '/' + window.location.href.split('/').slice(3).join('/')
  await request(template, {
    input: {
      ...input,
      source: 1,
      path,
      referrer: store.mkt.referrer,
      key: store.mkt.key,
      campaignId: store.mkt.campaignId
    }
  })
}
