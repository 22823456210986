export default function useStatic(initState) {
  const [state] = useState(initState)
  const [, setKey] = useState(null)
  const mutate = (modified = {}) => {
    Object.keys(modified).forEach(key => {
      state[key] = modified[key]
    })
    setKey(uuid())
  }

  return {
    state,
    mutate
  }
}
