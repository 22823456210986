export default function saveFileWithProgress(src, onProgress) {
  return new Promise(async(resolve, reject) => {
    const file = await getFile(src)
    const formData = new FormData()
    formData.append('file', file)
    const request = new XMLHttpRequest()
    request.upload.addEventListener('progress', e => {
      const percent = Math.round(e.loaded / e.total)
      onProgress(percent)
    })
    onProgress(0)

    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status !== 200) {
          $alert('上傳失敗', 'error')
          reject()
          return
        }
        const { filename } = JSON.parse(request.response)
        resolve(filename)
      }
    }
    request.open('post', '/api-v2/file/upload')
    request.timeout = 45000
    request.send(formData)
  })
}

const getFile = async file => {
  if (typeof file !== 'string') return file
  const res = await fetch(file)
  const buffer = await res.arrayBuffer()
  return new File([buffer], uuid(), { type: 'images/jpeg' })

}
