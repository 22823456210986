import { request, gql } from 'graphql-request'

global.request = async(template, variables) => {
  const fetchPath = () => {
    if (process.browser) return ''
    return process.env.API_SERVER
  }
  const path = `${fetchPath()}/api-v2/graphql`
  try {
    const data = await request(path, gql`${template}`, variables)
    return data
  } catch (error) {
    const errors = error?.response?.errors || []
    const firstError = errors[0]
    if (!firstError) throw error
    const exception = firstError.extensions.exception
    if (!exception) throw error
    if (!process.browser) throw exception
    if (!exception.silent) $alert(firstError.message, 'error')
    if (exception.code === 'notLogin') {
      h.refreshCurrent()
      h.openAuth()
    }
    throw exception
  }
}
