import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { grey } from '@mui/material/colors'

const theme = createTheme({
  palette: {
    primary: { main: grey[900] },
    white: { main: grey[50] }
  },
  typography: { button: { textTransform: 'none' } }
})

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={ theme }>
      { children }
    </ThemeProvider>
  )
}
