export default function useStoreFactory(useStoreState, { initData, ...factoryDic } = {}) {
  const store = useStoreState(initData)

  const hooks = Object.keys(factoryDic).reduce((carry, next) => ({
    ...carry,
    [next]: factoryDic[next](store)
  }), {})

  return {
    ...store,
    ...store.state,
    ...hooks
  }
}
