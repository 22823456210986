import { useFilter } from 'facades'

export default function Secondary() {
  const { state, mutate } = useStatic({ visible: false })
  const setVisible = value => {
    state.visible = value
    mutate()
  }
  const { setFilter, init, checkPathLocation, checkFilterVisible } = useFilter()
  const router = useRouter()
  useEffect(() => {
    init()
    setVisible(true)
  }, [])

  useEffect(() => {
    checkPathLocation()
  }, [router])

  const renderSelect = () => {
    if (!state.visible) return null
    const keys = ['city', 'district', 'hairLength', 'service']
    const className = '-translate-y-[12px] max-w-[10rem] flex-1'
    return keys.filter(key => checkFilterVisible(key)).map(key => (
      <C.Select
        key={ key }
        className={ className }
        { ...setFilter(key) }
      />
    ))
  }
  return (
    <div className='container flex space-x-2 justify-center py-2'>
      { renderSelect() }
    </div>
  )
}
