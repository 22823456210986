export default function og({ image, ...dic }) {
  const renderImage = () => {
    if (!image) return []
    const fetchPhoto = () => {

      if (typeof image === 'string') {
        return {
          url: image,
          width: 1200,
          height: 627
        }
      }
      return image
    }
    const photo = fetchPhoto()

    return [
      <meta property='og:image' content={ photo.url } key='og:image' />,
      <meta property='og:image:width' content={ photo.width } key='og:image:width' />,
      <meta property='og:image:height' content={ photo.height } key='og:image:height' />
    ]
  }

  const renderKeys = () => {
    const keys = Object.keys(dic)
    return keys.map(key => <meta property={ `og:${key}` } content={ dic[key] } key={ `og:${key}` }/>)
  }

  return [
    ...renderImage(),
    ...renderKeys()
  ]
}
