import { useEffect } from 'react'

export default function useBodyScroll({ state, mutate, fetcher, resolver, fetcherParams, reset = false, isReady = true, showLoadMore = false, limit = 20, startLimit }, watch = []) {
  fetcherParams ||= () => {}
  useEffect(() => {
    if (reset) state.records = []

    state.scrollConfig ||= { ajaxing: false }
    state.scrollConfig.keyDic = {}
    state.scrollConfig.offset = state.records.length
    state.scrollConfig.isFinish = true

    resolver ||= record => state.records.push(record)
    const action = async() => {
      if (!isReady) return
      if (state.scrollConfig.ajaxing) return
      state.scrollConfig.ajaxing = true
      const key = state.key || 'id'
      state.fetching = true
      mutate()

      try {
        const _limit = state.scrollConfig.offset > 0 || !startLimit ? limit : startLimit
        const records = await fetcher({
          ...fetcherParams(),
          offset: state.scrollConfig.offset,
          limit: _limit
        })

        records.forEach(record => {
          if (state.scrollConfig.keyDic[record[key]]) return
          state.scrollConfig.keyDic[record[key]] = true
          resolver(record)
        })

        state.scrollConfig.offset += _limit
        state.scrollConfig.isFinish = records.length < _limit

      } finally {
        state.scrollConfig.ajaxing = false
        state.fetching = false
        mutate()
      }
    }

    action()

    if (showLoadMore) {
      state.loadMoreClick = () => action()
      return
    }

    const onScroll = infinite(() => {
      if (state.fetching) return
      if (state.scrollConfig.isFinish) return
      action()
    }, false, true)

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, watch)
}
