import fetchNotifications from './fetch-notifications'
import notificationRead from 'request/notification/read'

export default function Notify() {
  const { state, mutate } = useStatic({
    visible: false,
    notifications: [],
    fetching: true,
    finish: false
  })
  const { user } = useCurrent()

  s.NotificationRead = () => {
    if (state.visible) return
    reset()
    mutate()
  }

  s.NotificationUpdate = ({ message, totalUnread }) => {
    $alert(message)
    reset()
    if (state.visible) return fetchNotifications({ state, mutate })
    user.unreadMyfollowing = totalUnread
    mutate()
  }

  const reset = () => {
    user.unreadMyfollowing = 0
    state.init = false
    state.finish = false
    state.notifications = []
  }

  useEffect(() => {
    if (!state.visible) return
    if (user.unreadMyfollowing > 0) reset()
    if (state.init) return
    fetchNotifications({ state, mutate })
  }, [state.visible])

  const onScroll = () => infinite(() => {
    if (state.finish) return
    if (state.fetching) return
    fetchNotifications({ state, mutate })
  })

  const closePanel = () => mutate({ visible: false })

  return (
    <div className='relative group'>
      <div
        className='translate-y-[1px] scale-105 mx-[4px] cursor-pointer'
        onClick={ () => mutate({ visible: true }) }
      >
        <C.Image
          src={ cdn('isvg/alert.svg') }
          className='w-[21px] h-[20px]'
          type='contain'
        />
      </div>
      <div className='absolute -top-[14px] -right-1'>
        <C.Badge count={ user.unreadMyfollowing } className='text-[0.5rem]' />
      </div>
      { state.init && !user.unreadMyfollowing &&
        <div className='absolute z-50 top-0 -right-1 sm:block hidden opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 transition-all scale-90 group-hover:scale-100'>
          <div className='bg-white w-[24rem] max-h-[20rem] min-h-[10rem] shadow mt-[1.95rem] py-2 overflow-scroll' onScroll={ onScroll() }>
            <List state={ state } closePanel={ closePanel } onScroll={ () => {} }/>
          </div>
        </div>
      }
      <div className='sm:hidden'>
        <NotifyPanel state={ state } closePanel={ closePanel } onScroll={ onScroll } />
      </div>
    </div>
  )
}

const NotifyPanel = parent => {
  const t = useI18n()
  return (
    <C.Modal
      noPadding
      visible={ parent.state.visible }
      close={ parent.closePanel }
    >
      <div className='flex flex-col'>
        <div className='shrink-0 text-black flex items-center justify-center text-center py-4 font-medium border-b '>
          { t('common:notifications') }
        </div>
        <List { ...parent }/>
      </div>
    </C.Modal>
  )
}

const List = ({ state, closePanel, onScroll }) => (
  <div className='overflow-scroll' onScroll={ onScroll() }>
    { state.notifications.map(notification => (
      <Row
        key={ notification.id }
        notification={ notification }
        closePanel={ closePanel }
      />
    )) }
    { state.fetching && <div className='py-5'><C.Loading /></div> }
    { !state.fetching && !state.notifications.length && <div className='text-center py-8'>尚無通知</div>}
  </div>
)

const Row = ({ notification, closePanel }) => {
  const { state, mutate } = useStatic({ isRead: notification.isRead })
  const {
    message,
    createdAt,
    notifierUser,
    actionType,
    actionId,
    actionUrl,
    photoUrls
  } = notification
  const photos = photoUrls || []

  const actionResolver = () => {
    switch (actionType) {
      case 'post':
        return h.openPost({ id: actionId })
      case 'profile':
        closePanel()
        return h.path.go.stylist({ id: actionId })
      case 'url':
        window.location = actionUrl
      default:
    }
  }
  const notificationClick = async() => {
    if (state.isRead) {
      notificationRead(notification.id)
      mutate({ isRead: true })
    }
    actionResolver()
  }

  return (
    <div className='py-2 px-3 cursor-pointer hover:bg-gray-100 flex relative'
      onClick={ notificationClick }>
      <div className='pr-2 mt-1'>
        <C.Avatar size='8' src={ notifierUser.avatar }/>
      </div>
      <div>
        <div className={ cn('text-[0.8rem]', { 'font-medium text-black': !state.isRead }) }>{ message }</div>
        <div className='flex pt-[0.15rem]'>
          <div className='text-xs shrink-0 pr-2'>
            <C.FromNow time={ createdAt }/>
          </div>
          <div className='flex flex-wrap mt-[0.15rem]'>
            { photos.map((src, idx) => (
              <C.Image
                key={ idx }
                src={ src }
                className='w-10 h-10 mr-1 mb-1'
              />
            )) }
          </div>
        </div>
      </div>
    </div>
  )
}
