import './icon'

import dynamic from 'next/dynamic'
import Head from 'next/head'

global.C = {
  Head,
  UserOnly: dynamic(() => import('components/authenticate/user-only')),
  StylistOnly: dynamic(() => import('components/authenticate/stylist-only')),
  AdminOnly: dynamic(() => import('components/authenticate/admin-only')),
  Modal: dynamic(() => import('components/utils/modal')),
  Panel: dynamic(() => import('components/utils/modal/panel')),
  Breadcrumb: dynamic(() => import('components/utils/breadcrumb')),
  Transition: dynamic(() => import('components/utils/transition')),
  Button: dynamic(() => import('components/utils/button')),
  ButtonIcon: dynamic(() => import('components/utils/button/icon')),
  Badge: dynamic(() => import('components/utils/badge')),
  Link: dynamic(() => import('components/utils/link')),
  Loading: dynamic(() => import('components/utils/loading')),
  Blur: dynamic(() => import('components/utils/blur')),
  Avatar: dynamic(() => import('components/utils/avatar')),
  FromNow: dynamic(() => import('components/utils/time/from-now')),
  Switch: dynamic(() => import('components/utils/switch')),
  Bar: dynamic(() => import('components/common/chart/bar')),
  Line: dynamic(() => import('components/common/chart/line')),
  MonthPicker: dynamic(() => import('components/utils/month-picker')),
  Input: dynamic(() => import('components/utils/input')),
  InputForm: dynamic(() => import('components/utils/input/form')),
  Select: dynamic(() => import('components/utils/select')),
  SelectForm: dynamic(() => import('components/utils/select/form')),
  Image: dynamic(() => import('components/utils/image')),
  ProgressBar: dynamic(() => import('components/utils/progress-bar')),
  DatePicker: dynamic(() => import('components/utils/date-picker')),
  DatePickerForm: dynamic(() => import('components/utils/date-picker/form')),
  Currency: dynamic(() => import('components/utils/currency')),
  Stars: dynamic(() => import('components/utils/stars')),
  SelectPhoto: dynamic(() => import('components/utils/select-photo')),
  SelectTag: dynamic(() => import('components/utils/select-tag')),
  Liked: dynamic(() => import('components/utils/liked')),
  AsyncSelectForm: dynamic(() => import('components/utils/async-select/form')),
  AsyncSelectUserForm: dynamic(() => import('components/utils/async-select/user-form')),
  AsyncSelectPage: dynamic(() => import('components/utils/async-select/page')),
  AsyncSelect: dynamic(() => import('components/utils/async-select')),
  CustomSelectForm: dynamic(() => import('components/utils/custom-select/form')),
  CustomSelect: dynamic(() => import('components/utils/custom-select')),
  CustomEditPanel: dynamic(() => import('components/utils/custom-edit-panel')),
  ComboBox: dynamic(() => import('components/utils/combo-box')),
  ComboBoxForm: dynamic(() => import('components/utils/combo-box/form')),
  Accordion: dynamic(() => import('components/utils/accordion')),
  PaymentDetail: dynamic(() => import('components/utils/payment/details')),
  PaymentSelect: dynamic(() => import('components/utils/payment/select')),
  PaymentPolicy: dynamic(() => import('components/utils/payment/policy')),
  PaymentDiscount: dynamic(() => import('components/utils/payment/discount')),
  ModalHeader: dynamic(() => import('components/utils/modal/header')),
  CommonIntro: dynamic(() => import('components/common/intro')),
  CalendarPicker: dynamic(() => import('components/utils/calendar-picker')),
  CheckBox: dynamic(() => import('components/utils/check-box')),
  CheckBoxForm: dynamic(() => import('components/utils/check-box/form')),
  RadioButtons: dynamic(() => import('components/utils/radio-buttons')),
  RadioButtonsForm: dynamic(() => import('components/utils/radio-buttons/form')),
  SingleSelectPhoto: dynamic(() => import('components/utils/single-select-photo')),
  ToggleButton: dynamic(() => import('components/utils/toggle-button')),
  FloatDot: dynamic(() => import('components/utils/float-dot')),
  QRCode: dynamic(() => import('components/utils/qrcode'))
}
