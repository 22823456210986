import postCollectCreate from 'request/common/post-collect-create'
import postCollectDelete from 'request/common/post-collect-delete'

export default function PhotoWithLike({ photo, width, sizes }) {
  const t = useI18n()
  const panel = usePanel()
  const { user } = useCurrent()
  const { state, mutate } = useStatic({ liked: photo.liked })
  const openPost = () => {
    h.openPost({ id: photo.postId })
  }

  const renderWidth = () => {
    if (width) return width
    return 'w-1/3'
  }

  const toggleLiked = () => {
    if (!user) return panel.open('auth')
    state.liked = !state.liked
    postCollect(photo.postId, state.liked, t)
    mutate()
  }

  return (
    <div className={ cn('relative cursor-pointer', renderWidth()) }>
      <div onClick={ openPost }>
        <C.Image
          sizes={ sizes }
          src={ photo.src }
          className='pt-[100%]'
          alt={ photo.alt }
        />
      </div>
      <div
        className={ cn('absolute right-1 bottom-1 cursor-pointer', { 'opacity-60 hover:opacity-100': !state.liked }) }
        onClick={ toggleLiked }
      >
        <C.Liked value={ state.liked } />
      </div>

      {
        photo.beforePhoto && <div className='w-2/5 h-2/5 absolute left-0 bottom-0 rounded-full p-1 sm:p-2 sm:w-1/3 sm:h-1/3' onClick={ openPost }>
          <C.Image className='pt-[100%] rounded-full shadow-[0_0_10px_1px_rgba(0,0,0,0.3)]' src={ photo.beforePhoto } />
        </div>
      }
    </div>
  )
}

const postCollect = (postId, liked, t) => {
  if (!liked) return postCollectDelete(postId)
  $alert(t('common:collected'))
  mkt.postCollect(postId)
  return postCollectCreate(postId)
}
