export default async function saveFile(src) {
  if (Array.isArray(src)) {
    return Promise.all(src.map(s => saveFile(s)))
  }
  const file = await getFile(src)
  const formData = new FormData()
  formData.append('file', file)
  const res = await fetch('/api-v2/file/upload', { method: 'POST', body: formData })
  const { filename } = await res.json()
  return filename
}

const getFile = async file => {
  if (typeof file !== 'string') return file
  const res = await fetch(file)
  const buffer = await res.arrayBuffer()
  return new File([buffer], uuid(), { type: 'images/jpeg' })

}
