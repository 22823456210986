const state = {
  categoryOptions: [
    { value: 'hair', text: '美髮' },
    { value: 'nail', text: '美甲' },
    { value: 'scalp', text: '頭皮' },
    { value: 'eyebrow', text: '霧眉' },
    { value: 'eyelash', text: '美睫' },
    { value: 'massage', text: '按摩' },
    { value: 'hairRemoval', text: '除毛' },
    { value: 'lips', text: '霧唇' }
  ],
  categoryDic: {
    hair: '美髮',
    nail: '美甲',
    scalp: '頭皮',
    eyebrow: '霧眉',
    eyelash: '美睫',
    massage: '按摩',
    hairRemoval: '除毛',
    lips: '霧唇'
  }
}

export default function useTagConfig() {
  return state
}
