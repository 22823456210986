import 'styles/globals.css'
import 'bootstrap'
import dynamic from 'next/dynamic'
import Theme from 'components/utils/theme'
import Navbar from 'components/core/navbar'

const Panel = dynamic(() => import('components/panel'))
const Core = dynamic(() => import('components/core'))
const Footer = dynamic(() => import('components/core/footer'))

const App = ({ Component, pageProps }) => {
  const t = useI18n()
  const title = t.seo.title('root')
  const description = t('seo:description')
  const router = useRouter()
  const url = h.path.domain + router.asPath
  store.ctx.router = router
  router.url = url
  const canonical = url.split('?')[0]
  return (<>
    <Theme>
      <C.Head>
        <base href={ h.path.domain }/>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
        { h.seo({ title, description, canonical }) }
        {
          h.og({
            url: canonical,
            type: 'website',
            site_name: t('seo:siteName'),
            image: {
              url: cdn('images/stylemap_v2.jpg'),
              width: 1200,
              height: 628
            }
          })
        }
        <meta property='fb:app_id' content='1706804032925164' />
        <meta name='facebook-domain-verification' content='rikxsktvub6qgwh5trmnkz216xj9cu' />
        <link rel='shortcut icon' href={ cdn('favicon4.ico') } />
      </C.Head>
      <Navbar/>
      <Core />
      <Component { ...pageProps } />
      <Footer />
      <Panel/>
    </Theme>
  </>
  )
}

export default App
