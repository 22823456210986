import initNewForm from './init-new-form'
import { inject } from './error-injector'

export default function useForm(...props) {
  const [form, updateForm] = useState(initNewForm(...props))

  form.update = ((prop, value) => {
    const newForm = fetchNewForm(form, prop, value)
    inject(newForm, prop)
    updateForm(newForm)
  })

  form.updateValues = (values => {
    let newForm = form

    Object.entries(values).forEach(([prop, value]) => {
      newForm = fetchNewForm(newForm, prop, value)
      inject(newForm, prop)
    })

    updateForm(newForm)
  })

  form.failedAt = () => {
    const errorField = inject(form)
    updateForm({ ...form })
    return errorField
  }

  return form
}

const fetchNewForm = (form, prop, value) => ({
  ...form,
  [prop]: {
    ...form[prop],
    init: false,
    value
  }
})
