const template = `
  query Init {
    cityDistrict {
      cities {
        id
        name
        key
        lat
        lng
      }
      districts {
        id
        cityName
        name
        key
        lat
        lng
      }
    }
  }
`

export default async function init() {
  const { cityDistrict } = await request(template)
  return cityDistrict
}
