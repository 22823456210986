import { range } from 'lodash-es'

Number.prototype.currency = function(addSign) {
  const number = Math.abs(this).format()
  if (this >= 0){
    if (!addSign) return `$${number}`
    return `+$${number}`
  }
  return `-$${number}`
}

Number.prototype.format = function() {
  return Intl.NumberFormat().format(this)
}

Number.prototype.slotToTime = function() {
  let slot = this
  if (slot < 0) slot = 0
  if (slot > 47) slot = 47

  let hh = slot < 20 ? `0${slot >> 1}` : `${slot >> 1}`
  if ((slot & 1) === 0) return `${hh}:00`
  return `${hh}:30`
}

Number.prototype.round = function(precision = 0) {
  const divider = 10 ** (-precision)
  return Math.round(this * divider) / divider
}

Number.prototype.range = function({ includeSelf = false, start = 0 }) {
  return range(start, includeSelf ? this + 1 : this)
}