String.prototype.countDown = function() {
  const diff = dayjs(this.toString()).diff(dayjs(), 'second')
  if (diff <= 0) return '00:00'
  const minute = () => parseInt(diff / 60).toString().padStart(2, '0')

  const second = () => (diff % 60).toString().padStart(2, '0')
  return `${minute()}:${second()}`
}

String.prototype.ymd = function(split = '-') {
  return dayjs(this.toString()).format(`YYYY${split}MM${split}DD`)
}

String.prototype.ymdDash = function() {
  return dayjs(this.toString()).format('YYYY/MM/DD')
}

String.prototype.ymdw = function() {
  return dayjs(this.toString()).format('YYYY/MM/DD (dd)')
}

String.prototype.ymdwhm = function() {
  return dayjs(this.toString()).format('YYYY/MM/DD (dd) HH:mm')
}

String.prototype.ymdhm = function() {
  return dayjs(this.toString()).format('YYYY-MM-DD HH:mm')
}

String.prototype.yearMonth = function() {
  return dayjs(this.toString()).format('YYYY-MM')
}

String.prototype.md = function() {
  return dayjs(this.toString()).format('MM/DD')
}

String.prototype.mdhm = function() {
  return dayjs(this.toString()).format('MM/DD HH:mm')
}

String.prototype.hm = function() {
  return dayjs(this.toString()).format('HH:mm')
}

String.prototype.age = function() {
  return dayjs().year() - dayjs(this.toString()).year()
}

String.prototype.toNumber = function() {
  return parseFloat(this.toString()) || 0
}

String.prototype.currency = function() {
  return this.toNumber().currency()
}

String.prototype.diff = function(time) {
  return dayjs(this.toString()).diff(dayjs(time))
}

String.prototype.slot = function() {
  const time = dayjs(this.toString())
  return time.slot()
}

String.prototype.isUrl = function() {
  return this.slice(0, 4) === 'http'
}

const URL_REGEX = /(\b(https?):\/\/(?!cdn\.style-map|www\.youtube\.com\/embed)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim

String.prototype.urls = function() {
  return this.toString()
    .match(URL_REGEX) || []
}

String.prototype.noNewLine = function() {
  return this.toString()
    .replace(/\r\n/g, ' ')
    .replace(/\n/g, ' ')
}

String.prototype.escaped = function() {
  const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
  return this.toString()
    .replace(SCRIPT_REGEX, '')
    .replace(URL_REGEX, '<a class="text-link" href="$1" target="_blank">$1</a>')
    .trim()
    .replace(/\n/g, '<br>')
}

String.prototype.rawHtml = function(escaped = true) {
  let __html = this
  if (escaped) __html = this.escaped()
  return { dangerouslySetInnerHTML: { __html } }
}

String.prototype.isEmail = function() {
  return !!this.toString().match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

String.prototype.pascalCase = function() {
  return this[0].toUpperCase() + this.slice(1)
}
