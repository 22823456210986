import { useFilter } from 'facades'
import Notify from './notify'
import Search from './search'
import SecondaryBar from './secondary-bar'

export default function Navbar() {
  const { user, isAdmin, fromApp, logout } = useCurrent()
  const filter = useFilter()
  const { open } = usePanel()
  const { state, mutate } = useStatic({ menuVisible: false })
  const openAuth = () => open('auth')
  const t = useI18n()

  s.GroupUsersUpdate = ({ groupUsers }) => {
    const userGroupDic = groupUsers.keyBy('id')
    user.userGroups.forEach(u => {
      const info = userGroupDic[u.id]
      if (!info) return
      u.unreadCount = info.unread_num
    })
    mutate()
  }

  const closePanel = e => {
    e.stopPropagation()
    state.menuVisible = false
    mutate()
  }

  const optionClick = callback => e => {
    closePanel(e)
    callback()
  }

  h.openAuth = () => openAuth()

  const renderInfo = () => {
    const changeLocale = () => h.openChangeLocale()
    const unreadCount = user?.userGroups.filter(u => u.id !== user.id)
      .map(u => u.unreadCount).sum()

    const style = () => {
      const base = 'absolute z-50 right-0 top-[1.5rem] transition-all w-[13rem] translate-x-3'
      if (!h.isMobile()) {
        const hover = 'group-hover:opacity-100 group-hover:scale-100 group-hover:pointer-events-auto'
        return cn(base, 'pointer-events-none scale-90 opacity-0 ', hover)
      }
      if (state.menuVisible) return cn(base, 'opacity-100 scale-100')
      return cn(base, 'pointer-events-none opacity-0 scale-50')
    }

    const myCollectClick = () => h.openCollection({
      type: 'collection',
      id: user?.id,
      title: t('common:myCollect')
    })
    const referralClick = h.path.bind.referral()
    const myBookingClick = () => h.openBookingRecords()
    const myDepositClick = () => h.openMyDeposit()
    const cardSettingClick = () => h.openCardSetting({
      type: 'cardSetting',
      path: window.location.pathname,
      source: {}
    })
    const accountSettingClick = () => h.openAccountSetting()
    const newPostCallback = () => h.openCollection({
      type: 'user',
      id: user?.id,
      title: user?.name
    })
    const renderStylistOptions = () => {
      const { id, stylist } = user
      if (!stylist?.show || !stylist?.showCalendar || !stylist?.studioId) return
      const myPageClick = h.path.bind.stylist({ id })
      const studioManageClick = h.path.bind.studioManage([stylist.studioId])
      const newPostClick = () => h.openPostCreate({ user, callback: newPostCallback })
      const purchaseRecordClick = () => h.openVendorReceipts({ userId: user.id })
      return (
        <>
          <Option iconKey='account' onClick={ optionClick(myPageClick) }>{ t('common:myPage') }</Option>
          <Option iconKey='plus' onClick={ optionClick(newPostClick) }>{ t('common:newPost') }</Option>
          <Option iconKey='studio' onClick={ optionClick(studioManageClick) }>{ t('common:studioManage') }</Option>
          <Option iconKey='cart' onClick={ optionClick(purchaseRecordClick) }>{ t('common:purchaseRecord') }</Option>
        </>
      )
    }
    const renderAdminOptions = () => {
      if (!isAdmin) return
      const styleMapManageClick = () => h.path.go.manage('stylist')
      return <Option iconKey='stylemap' onClick={ optionClick(styleMapManageClick) }>美配後台</Option>
    }

    const openSwitchAccount = () => h.openSwitchAccount()
    const renderSwitchAccount = () => {
      if (user.userGroups.length < 2) return
      return (
        <Option iconKey='switch' onClick={ optionClick(openSwitchAccount) }>
          <div className='flex items-center'>
            <div>
              { t('common:switchAccount') }
            </div>
            <div className='shrink-0 text-right ml-2 -mt-[0.3rem]'>
              <C.Badge count={ unreadCount } />
            </div>
          </div>
        </Option>
      )
    }

    const openMenu = () => {
      state.menuVisible = true
      mutate()
    }

    const backgroundMask = () => {
      if (!h.isMobile()) return
      return (
        <div className={ cn('fixed inset-0', { hidden: !state.menuVisible }) }
          onClick={ closePanel }
        />
      )
    }
    const renderNotify = () => {
      if (!user) return
      return <Notify />
    }
    const renderAvatar = () => {
      if (!user) return <div className='cursor-pointer scale-90 sm:scale-95 text-gray-500' >
        <C.Avatar src={ cdn('user/default/profile_pic2_64.png') } size='6' />
      </div>
      return <div>
        <C.Avatar src={ user.avatar } size='6' />
        <div className='absolute -top-[12px] -right-[12px]'>
          <C.Badge count={ unreadCount } />
        </div>
      </div>
    }
    const renderOptions = () => {
      if (!user) return <div className='bg-white shadow py-2 mt-2'>
        <Option iconKey='login' onClick={ optionClick(openAuth) }>{ t('common:loginRegister') }</Option>
        <Option iconKey='changeLocale' onClick={ optionClick(changeLocale) }>{ t('common:changeLocale') }</Option>
        <C.Link href={ h.path.joinUs() } noUnderline><Option iconKey='join'>{ t('common:stylistJoin') }</Option></C.Link>
      </div>
      return <div className='bg-white shadow py-2 mt-2'>
        <div className='border-b pb-2 mx-3 mb-2 truncate font-medium'>{user.name}</div>
        <Option iconKey='heart' onClick={ optionClick(myCollectClick) }>{ t('common:myCollect') }</Option>
        <Option iconKey='calendar' onClick={ optionClick(myBookingClick) }>{ t('common:myBooking') }</Option>
        <Option iconKey='records' onClick={ optionClick(myDepositClick) }>{ t('common:myDeposit') }</Option>
        <Option iconKey='card' onClick={ optionClick(cardSettingClick) }>{ t('common:cardSetting') }</Option>
        <Option iconKey='setting' onClick={ optionClick(accountSettingClick) }>{ t('common:accountSetting') }</Option>
        <Option iconKey='dollar' onClick={ optionClick(referralClick) }>{ t('common:referral') }</Option>
        { renderStylistOptions() }
        { renderAdminOptions() }
        { renderSwitchAccount() }
        <Option iconKey='changeLocale' onClick={ optionClick(changeLocale) }>{ t('common:changeLocale') }</Option>
        <Option iconKey='logout' onClick={ optionClick(logout) }>{ t('common:logout') }</Option>
      </div>
    }

    return (
      <>
        { backgroundMask() }
        { renderNotify() }
        <div className='group relative' onClick={ openMenu } >
          { renderAvatar() }
          <div className={ style() }>
            { renderOptions() }
          </div>
        </div>
      </>
    )
  }

  const height = () => {
    if (!fromApp) {
      if (!filter.visible) return 'h-[60px]'
      return 'h-[110px]'
    }

    if (!filter.visible) return ''
    return 'h-[50px]'
  }

  const renderSecondaryBar = () => {
    if (!filter.visible) return
    return (
      <div className={ cn('overflow-hidden border-t') }>
        <SecondaryBar />
      </div>
    )
  }

  const renderMainBar = () => {
    if (fromApp) return null
    return (
      <div className='container flex items-center flex-1 h-[60px]'>
        <div className='relative text-center sm:text-left'>
          <C.Link href={ h.path.root() }>
            <div className='w-6 sm:w-32 overflow-hidden'>
              <C.Image
                type='contain'
                src={ cdn('svg/logo1.svg') }
                className='w-32 h-10'
                noLoading
              />
            </div>
          </C.Link>
        </div>
        <Search />
        <div className='flex justify-end items-center space-x-2 text-gray-500 sm:space-x-5 sm:w-32'>
          { renderInfo() }
        </div>
      </div>
    )
  }

  return (
    <div className='select-none'>
      <div className={ height() }></div>
      <div className={ cn('fixed w-full z-10 shadow-md bg-white/95 top-0', height()) }>
        { renderMainBar() }
        { renderSecondaryBar() }
      </div>
    </div>
  )
}

const Option = ({ iconKey, children, ...props }) => (
  <div className='py-2 px-3 cursor-pointer hover:bg-gray-100 text-gray-900'
    { ...props }
  >
    <div className='flex items-center space-x-2'>
      { iconDic[iconKey] }
      <div>{children}</div>
    </div>
  </div>
)

const iconDic = {
  heart: <Icon.Heart className='w-4 h-4'/>,
  calendar: <Icon.Calendar className='w-4 h-4'/>,
  records: <Icon.Records className='w-4 h-4'/>,
  card: <Icon.Card className='w-4 h-4'/>,
  setting: <Icon.Setting className='w-4 h-4'/>,
  dollar: <Icon.Dollar className='w-4 h-4'/>,
  account: <Icon.Account className='w-4 h-4'/>,
  plus: <Icon.Plus className='w-4 h-4'/>,
  studio: <Icon.Studio className='w-4 h-4'/>,
  cart: <Icon.Cart className='w-4 h-4'/>,
  stylemap: <C.Image src={ cdn('favicon3.png') } className='w-4 h-4'/>,
  switch: <Icon.Switch className='w-4 h-4'/>,
  logout: <Icon.Logout className='w-4 h-4'/>,
  login: <Icon.Login className='w-4 h-4'/>,
  changeLocale: <Icon.Language className='w-4 h-4'/>,
  join: <Icon.Join className='w-4 h-4'/>
}
