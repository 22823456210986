import isMobile from './is-mobile'
import isEmbedBrowser from './is-embed-browser'
import { v4 } from 'uuid'
import cn from 'classnames'
import imageToString from './image-to-string'
import seo from './seo'
import og from './og'
import saveFile from './save-file'
import ldjson from './ldjson'
import saveFileWithProgress from './save-file-with-progress'
import './request'
import './dayjs'
import './ctx'
import { path } from './path'
import './mkt'
import Cookies from 'js-cookie'

const mapUrl = (name, address, className) => {
  const href = `https://maps.google.com?q=${address}`
  return <C.Link className={ className } href={ href } blank noColor>{name}</C.Link>
}

const queryFetch = (...key) => {
  const queryString = location.search.split('?')[1]
  if (!queryString) return {}
  const dic = key.trueDic()
  const pairs = queryString.split('&')
  return pairs.reduce((carry, next) => {
    const [key, value] = next.split('=')
    if (!dic[key]) return carry
    carry[key] = value || true
    return carry
  }, {})
}

const scrollTargetFetch = (event, isBody) => {
  if (!isBody) return event.target
  if (document.compatMode === 'CSS1Compat') return event.target.documentElement
  return event.target.body
}
const scrollTo = async(ref, offset = -75) => {
  const top = ref.getBoundingClientRect().top + window.pageYOffset + offset
  window.scrollTo({ top, behavior: 'smooth' })
}

global.h = {
  imageToString,
  isMobile,
  isEmbedBrowser,
  seo,
  saveFile,
  saveFileWithProgress,
  ldjson,
  scrollTo,
  og,
  mapUrl,
  path,
  queryFetch
}
global.s = {}
global.rb = obj => <pre>{JSON.stringify(obj)}</pre>
global.cn = cn
global.uuid = v4
global.cdn = path => `https://cdn.style-map.com/${path}`
global.copyText = text => navigator.clipboard.writeText(text)
global.sleep = delay => (new Promise(resolve => setTimeout(resolve, delay * 1000)))

global.infinite = (callback, reverse, isBody) => event => {
  const target = scrollTargetFetch(event, isBody)
  const { clientHeight, scrollTop, scrollHeight } = target

  const notReachPoint = () => {
    if (reverse) return scrollTop > clientHeight * 3
    return scrollHeight - scrollTop > clientHeight * 3
  }
  if (notReachPoint()) return
  callback()
}

global.animate = (dom, position, duration = 250) => {
  let remaining = duration
  const frame = 25
  const move = () => {
    if (remaining <= 0) return
    setTimeout(() => {
      remaining -= frame
      dom.scrollTop += (position - dom.scrollTop) * (duration - remaining) / duration
      move()
    }, frame)
  }
  move()
}

global.getPriceRangeText = (minPrice, maxPrice, t) => {
  if (minPrice <= 0 && maxPrice <= 0) return t('common:noPrice')
  if (minPrice <= 0) return `${ maxPrice.currency() }`
  if (maxPrice <= 0) return `${ minPrice.currency() } ${ t('common:up') }`
  if (minPrice === maxPrice) return `${ minPrice.currency() }`
  return `${ minPrice.currency() } ~ ${ maxPrice.currency() }`
}

global.getDiscountPrice = (discount, t) => {
  const { discountType, value } = discount
  if (value <= 0) return ''
  const i18nPath = 'common:discount.discountType'
  switch (discountType) {
    case 1:
      let discountValue = 100 - value
      if (discountValue % 10 === 0) discountValue /= 10
      return `${ discountValue }${ t(`${i18nPath}.priceOff`) }`
    case 2:
      return `${ value }${ t(`${i18nPath}.priceDiscount`) }`
    default:
      return `${ t(`${i18nPath}.price`) } ${ value.currency() }`
  }
}

global.getServiceTimeText = (serviceTime, t) => {
  const hour = Math.floor(serviceTime / 60)
  const minute = serviceTime % 60
  let text = t('common:about')
  if (hour > 0) text += `${ hour }${ t('common:hour') }`
  if (minute > 0) text += `${ minute }${ t('common:minute') }`
  return text
}

global.createSocket = (onOpen, onMessage, onClose, onError) => {
  const ws = new WebSocket(process.env.NEXT_PUBLIC_SOCKET)
  ws.onopen = () => {
    onOpen(ws)
  }
  ws.onmessage = message => {
    if (message.data === 'ping') return
    onMessage(message, ws)
  }
  ws.onclose = () => {
    ws.close()
    if (onClose) onClose()
  }
  ws.onerror = () => {
    ws.close()
    if (onError) onError()
  }
  return ws
}
global.setCookie = (name, value, option = {}) => {
  const domain = process.env.NEXT_PUBLIC_DOMAIN.split('//')[1]
  const path = '/'
  Cookies.set(name, value, { expires: 365 * 10, domain, path, ...option })
}

global.getCookie = name => Cookies.get(name)
