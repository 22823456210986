import { useSearch } from 'facades'

export default function Search() {
  const t = useI18n()
  const { toggleVisible } = useSearch()
  return (
    <div className='flex-1 flex justify-center'>
      <div className='px-3 text-gray-500 hover:text-gray-600 py-[5px] rounded-full shadow-inner border cursor-pointer flex items-center w-[90%] sm:w-[80%] max-w-[18rem] hover:border-gray-400'
        onClick={ toggleVisible }
      >
        <div className='scale-75'>
          <Icon.Search />
        </div>
        <div className='px-1 text-sm sm:text-[0.9rem]'>
          <div className='line-clamp-1'>{ t('common:startSearch') }</div>
        </div>
        <div className='flex-1' />
      </div>
    </div>
  )
}
