const state = { phoneCodes: [] }

let init = false

export default function usePhoneCode() {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('hooks/phone-code', uuid)

  const loadInfo = async() => {
    init = true
    const res = await fetch('/api-v2/cache/phone-codes?locale=zh-TW')
    const { phoneCodes } = await res.json()
    state.phoneCodes = phoneCodes
    mutate()
  }

  useEffect(() => {
    if (init) return
    init = true
    loadInfo()
  }, [])

  return state
}
