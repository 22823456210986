export default function useI18n() {
  const { locale } = useRouter()
  const t = (key, params) => i18n.t(key, {
    ...params,
    lng: locale,
    returnObjects: true
  })

  t.time = value => dayjs(value).locale(locale)
  t.seo = {
    title: (key, { raw = false, ...params } = {}) => {
      const fetchTitle = () => {
        if (raw) return key
        return t(`seo:${key}.title`, params)
      }
      const title = fetchTitle()
      return t('seo:title', { title, interpolation: { escapeValue: false } })
    }
  }

  return t
}
