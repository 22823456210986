import notificationsRequest from 'request/notification/query'

export default async function fetchNotifications({ state, mutate }) {
  const limit = 20
  state.init = true
  state.fetching = true
  mutate()
  try {
    const fetched = await notificationsRequest({
      offset: state.notifications.length,
      limit
    })

    const idDic = state.notifications.keyBy('id')
    fetched.forEach(noti => {
      if (idDic[noti.id]) return
      state.notifications.push(noti)
    })

    state.finish = fetched.length < limit
  } finally {
    state.fetching = false
    mutate()
  }
}