import visitorLogCreate from 'request/common/visitor-log-create'

global.mkt = {
  login(user) {
    const { email, phone } = user
    global.enhanced_conversion_data = {}
    const userObj = {}
    if (phone) {
      userObj.ph = phone.replace('+', '')
      global.enhanced_conversion_data.phone_number = phone
    }
    if (email) {
      userObj.em = email
      global.enhanced_conversion_data.email = email
    }
    if (process.env.NEXT_PUBLIC_FB_PIXEL_ID) {
      fbq('init', process.env.NEXT_PUBLIC_FB_PIXEL_ID, userObj)
    }

    gtag('event', 'conversion', { send_to: 'AW-863904864/aowaCIj4mqEDEODI-JsD' })
    gtag('event', 'login')
    fbq('trackCustom', 'login')

    visitorLogCreate({ event: store.enum.mktEvent.login })
  },
  referralClick({ code, studioId }) {
    gtag('event', 'referral-click')
    fbq('trackCustom', 'referral-click')
    const params = JSON.stringify({ code, studioId })
    visitorLogCreate({ event: store.enum.mktEvent.referralClick, params })
  },
  referralSuccess({ code, studioId }) {
    gtag('event', 'referral-success')
    fbq('trackCustom', 'referral-success')
    const params = JSON.stringify({ code, studioId })
    visitorLogCreate({ event: store.enum.mktEvent.referralSuccess, params })
  },
  bindCardOpen() {
    gtag('event', 'bindcard-open')
    fbq('trackCustom', 'bindcard-open')
    visitorLogCreate({ event: store.enum.mktEvent.bindCardOpen })
  },
  bindCardSuccess() {
    gtag('event', 'bindcard-success')
    fbq('trackCustom', 'bindcard-success')
    visitorLogCreate({ event: store.enum.mktEvent.bindCardSuccess })
  },
  contactUs() {
    gtag('event', 'contact-us')
    fbq('trackCustom', 'contact-us')
    visitorLogCreate({ event: store.enum.mktEvent.contactUs })
  },
  joinUsContactUs(studioId) {
    gtag('event', 'join-us-contact-us')
    fbq('trackCustom', 'join-us-contact-us')
    const params = JSON.stringify({ studioId })
    visitorLogCreate({ event: store.enum.mktEvent.joinUsPageContactUs, params })
  },
  authOpen() {
    gtag('event', 'auth-open')
    fbq('trackCustom', 'auth-open')
    visitorLogCreate({ event: store.enum.mktEvent.authOpen })
  },
  authPhoneCode() {
    gtag('event', 'auth-phone-code')
    fbq('trackCustom', 'auth-phone-code')
    visitorLogCreate({ event: store.enum.mktEvent.authPhoneCode })
  },
  startSearch() {
    gtag('event', 'start-search')
    fbq('trackCustom', 'start-search')
    visitorLogCreate({ event: store.enum.mktEvent.startSearch })
  },
  postClick(postId) {
    gtag('event', 'post-click')
    fbq('trackCustom', 'post-click')
    const params = JSON.stringify({ postId })
    visitorLogCreate({ event: store.enum.mktEvent.postClick, params })
  },
  photoClick() {
    gtag('event', 'photo-click')
    fbq('trackCustom', 'photo-click')
    visitorLogCreate({ event: store.enum.mktEvent.photoClick })
  },
  postCollect(postId) {
    gtag('event', 'post-collect')
    fbq('trackCustom', 'post-collect')
    const params = JSON.stringify({ postId })
    visitorLogCreate({ event: store.enum.mktEvent.postCollect, params })
  },
  changeFilter(params) {
    gtag('event', 'change-filter')
    fbq('trackCustom', 'change-filter')
    visitorLogCreate({ event: store.enum.mktEvent.changeFilter, params })
  },
  chatRoomOpen() {
    gtag('event', 'chatroom-open')
    fbq('trackCustom', 'chatroom-open')
    visitorLogCreate({ event: store.enum.mktEvent.chatRoomOpen })
  },
  messageStylist(userId) {
    gtag('event', 'message-stylist')
    fbq('trackCustom', 'message-stylist')
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.messageStylist, params })
  },
  followClick() {
    gtag('event', 'follow-stylist')
    fbq('trackCustom', 'follow-stylist')
    visitorLogCreate({ event: store.enum.mktEvent.followClick })
  },
  messageOpen(userId) {
    gtag('event', 'message-open')
    fbq('trackCustom', 'message-open')
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.messageOpen, params })
  },
  bookingOpen(userId) {
    gtag('event', 'conversion', { send_to: 'AW-863904864/7AF-CIOKmnwQ4Mj4mwM' })
    gtag('event', 'booking-open')
    fbq('trackCustom', 'booking-open')
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.bookingOpen, params })
  },
  selectBookingTime(selectedTime) {
    gtag('event', 'select-booking-time')
    fbq('trackCustom', 'select-booking-time')
    const params = JSON.stringify({ selectedTime })
    visitorLogCreate({ event: store.enum.mktEvent.selectBookingTime, params })
  },
  prepayBookingClick() {
    gtag('event', 'prepay-booking-click')
    fbq('trackCustom', 'prepay-booking-click')
    visitorLogCreate({ event: store.enum.mktEvent.prepayBookingClick })
  },
  prepayBooking({ stylistUserId, amount, user }) {
    const { email, phone } = user
    global.enhanced_conversion_data = {
      email,
      phone_number: phone
    }
    gtag('event', 'conversion', {
      send_to: 'AW-863904864/Dxj5CNj0oqEDEODI-JsD',
      value: amount,
      currency: 'TWD',
      transaction_id: ''
    })
    gtag('event', 'prepay-booking')
    fbq('trackCustom', 'prepay-booking')
    const params = JSON.stringify({ stylistUserId, amount })
    visitorLogCreate({ event: store.enum.mktEvent.prepayBooking, params })
  },
  checkoutBookingClick() {
    gtag('event', 'checkout-booking-click')
    fbq('trackCustom', 'checkout-booking-click')
    visitorLogCreate({ event: store.enum.mktEvent.checkoutBookingClick })
  },
  checkoutBooking({ stylistUserId, amount }) {
    gtag('event', 'checkout-booking')
    fbq('trackCustom', 'checkout-booking')
    const params = JSON.stringify({ stylistUserId, amount })
    visitorLogCreate({ event: store.enum.mktEvent.checkoutBooking, params })
  },
  stylistPostOpen(userId) {
    gtag('event', 'stylist-post-open')
    fbq('trackCustom', 'stylist-post-open')
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.stylistPostOpen, params })
  },
  stylistReviewOpen(userId) {
    gtag('event', 'conversion', { send_to: 'AW-863904864/eWjTCK7jqrYDEODI-JsD' })
    gtag('event', 'stylist-review-open')
    fbq('trackCustom', 'stylist-review-open')
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.stylistReviewOpen, params })
  },
  studioPostOpen(studioId) {
    gtag('event', 'studio-post-open')
    fbq('trackCustom', 'studio-post-open')
    const params = JSON.stringify({ studioId })
    visitorLogCreate({ event: store.enum.mktEvent.studioPostOpen, params })
  },
  studioReviewOpen(studioId) {
    gtag('event', 'conversion', { send_to: 'AW-863904864/eWjTCK7jqrYDEODI-JsD' })
    gtag('event', 'studio-review-open')
    fbq('trackCustom', 'studio-review-open')
    const params = JSON.stringify({ studioId })
    visitorLogCreate({ event: store.enum.mktEvent.studioReviewOpen, params })
  },
  guaranteeReviewOpen() {
    gtag('event', 'conversion', { send_to: 'AW-863904864/eWjTCK7jqrYDEODI-JsD' })
    gtag('event', 'guarantee-review-open')
    fbq('trackCustom', 'guarantee-review-open')
    visitorLogCreate({ event: store.enum.mktEvent.guaranteeReviewOpen })
  },
  modelReviewOpen() {
    gtag('event', 'conversion', { send_to: 'AW-863904864/eWjTCK7jqrYDEODI-JsD' })
    gtag('event', 'model-review-open')
    fbq('trackCustom', 'model-review-open')
    visitorLogCreate({ event: store.enum.mktEvent.modelReviewOpen })
  },
  qaClick(idx) {
    gtag('event', 'qa-click')
    fbq('trackCustom', 'qa-click')
    const params = JSON.stringify({ q: idx })
    visitorLogCreate({ event: store.enum.mktEvent.qaClick, params })
  },
  dreamAgreementClick(userId) {
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.dreamAgreementClick, params })
  },
  consultingClick() {
    visitorLogCreate({ event: store.enum.mktEvent.consultingClick })
  },
  applyFormSubmit(userId) {
    gtag('event', 'apply-form-submit')
    fbq('trackCustom', 'apply-form-submit')
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.applyFormSubmit, params })
  },
  joinUsFormSubmit(userId) {
    gtag('event', 'join-us-form-submit')
    fbq('trackCustom', 'join-us-form-submit')
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.joinUsFormSubmit, params })
  },
  applyFormClick() {
    visitorLogCreate({ event: store.enum.mktEvent.applyFormClick })
  },
  stylistIgClick() {
    visitorLogCreate({ event: store.enum.mktEvent.stylistIgClick })
  },
  eventRuleClick() {
    gtag('event', 'event-rule-click')
    fbq('trackCustom', 'event-rule-click')
    visitorLogCreate({ event: store.enum.mktEvent.eventRuleClick })
  },
  eventStylistClick(userId) {
    gtag('event', 'event-stylist-click')
    fbq('trackCustom', 'event-stylist-click')
    const params = JSON.stringify({ userId })
    visitorLogCreate({ event: store.enum.mktEvent.eventStylistClick, params })
  },
  trackPageScroll(top) {
    const params = JSON.stringify({ top })
    visitorLogCreate({ event: store.enum.mktEvent.trackPageScroll, params })
  }
}
