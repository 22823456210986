export default function AudioInit() {
  const dic = {
    pop: '/sound/pop.mp3',
    messageReceived: '/sound/message_received.mp3',
    messageSent: '/sound/message_sent.mp3'
  }
  h.audio = {
    play: key => {
      const audio = new Audio(dic[key])
      const promise = audio.play()
      if (!promise) return
      promise.catch(error => console.log(error))
    }
  }
}
