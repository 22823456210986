const source = /FBAV|instagram|Line/i

let result = 'init'

function test() {
  return navigator && navigator.userAgent && source.test(navigator.userAgent)
}

export default function isEmbedBrowser() {
  if (result !== 'init') return result
  result = test()
  return result
}
