const state = { current: '' }
export default function useLoading() {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('/hooks/loading', uuid)
  state.setLoading ||= target => {
    state.current = target
    mutate()
  }
  return state
}
