import requestConfig from 'request/config/init'
const cache = {}

const configInit = async() => {
  if (cache.cities) return
  const { cities, districts } = await requestConfig()
  cache.cities = cities
  cache.districts = districts
}

const fetchCities = async() => {
  if (cache.cities) return cache.cities
  await configInit()
  return cache.cities
}

const fetchDistricts = async() => {
  if (cache.districts) return cache.districts
  await configInit()
  return cache.districts
}

global.store = {
  mkt: {
    key: null,
    referrer: null,
    campaignId: null
  },
  ctx: { router: null },
  plugin: { firebase: null, firebaseSignIn: false },
  filter: {
    city: null,
    district: null
  },
  autoClose: {
    skipOnce: false,
    closing: false,
    register: ({ key, visible, close }) => {
      if (visible) {
        store.autoClose.dic[key] = close
        return
      }
      delete store.autoClose.dic[key]
    },
    dic: {},
    closePanels: () => {
      if (store.autoClose.closing) return
      store.autoClose.closing = true
      const callbacks = Object.values(store.autoClose.dic)
      callbacks.forEach(callback => callback())
      store.autoClose.closing = false
    }
  },
  findCity: async(key, field = 'id') => {
    const cities = await fetchCities()
    key = key?.toString()
    return cities.find(v => key === v[field])
  },
  findDistrict: async(cityName, key, field = 'id') => {
    const districts = await fetchDistricts()
    key = key?.toString()
    return districts.find(v => key === v[field] && cityName === v.cityName)
  },
  fetchCities,
  fetchDistricts,
  enum: {
    mktEvent: {
      login: 1,
      contactUs: 2,
      joinUsPageContactUs: 3,
      authOpen: 4,
      authPhoneCode: 5,
      startSearch: 6,
      postClick: 7,
      photoClick: 8,
      postCollect: 9,
      changeFilter: 10,
      chatRoomOpen: 11,
      messageStylist: 12,
      followClick: 13,
      messageOpen: 14,
      bookingOpen: 15,
      selectBookingTime: 16,
      prepayBooking: 17,
      checkoutBooking: 18,
      stylistPostOpen: 19,
      stylistReviewOpen: 20,
      studioPostOpen: 21,
      studioReviewOpen: 22,
      referralClick: 23,
      referralSuccess: 24,
      bindCardOpen: 25,
      bindCardSuccess: 26,
      qaClick: 27,
      dreamAgreementClick: 28,
      appReviewOpen: 29,
      consultingClick: 30,
      guaranteeReviewOpen: 31,
      applyFormSubmit: 32,
      modelReviewOpen: 33,
      joinUsFormSubmit: 34,
      applyFormClick: 35,
      stylistIgClick: 36,
      eventRuleClick: 37,
      eventStylistClick: 38,
      trackPageScroll: 39,
      prepayBookingClick: 40,
      checkoutBookingClick: 41
    }
  },
  serviceCategories: [
    'haircut',
    'dye',
    'perm',
    'hairextension',
    'haircare',
    'hairwash',
    'scalp',
    'nail',
    'eyebrow',
    'eyelash',
    'massage',
    'hairRemoval',
    'lips',
    'other'
  ],
  modelReviewCategoryMap: {
    haircut: 'hair',
    dye: 'hair',
    perm: 'hair',
    haircare: 'hair',
    scalp: 'scalp',
    nail: 'nail',
    eyebrow: 'eyebrow',
    eyelash: 'eyelash',
    lips: 'lips',
    hairRemoval: 'hairRemoval'
    // hairwash: 'hairwash',
    // hairextension: 'hairextension',
    // massage: 'massage',
    // other: 'other'
  },
  modelPhotoSelectCategories: ['haircut', 'dye', 'perm'],
  filterCategories: [
    'haircut',
    'dye',
    'perm',
    'haircare',
    'scalp',
    //'eyebrow',
    //'lips'
    //'eyelash',
    'nail'
  ],
  stylistEventFilterCategories: [
    'haircut',
    'dye',
    'perm',
    //'eyebrow',
    //'lips',
    //'eyelash'
    'nail'
  ],
  stylistEventServiceIdDic: {
    1: 'haircut',
    72: 'eyelash',
    73: 'eyelash',
    70: 'eyebrow',
    75: 'lips',
    43: 'nail',
    33: 'dye',
    45: 'perm'
  },
  stylistEventRuleDic: {
    newUser: [
      { quota: 3, haircutRatio: 0.3, othersRatio: 0.6 },
      { quota: 6, haircutRatio: 0.5, othersRatio: 0.7 },
      { quota: 99, haircutRatio: 0.7, othersRatio: 0.8 }
    ],
    newCustomer: [
      { quota: 3, haircutRatio: 0.5, othersRatio: 0.7 },
      { quota: 6, haircutRatio: 0.7, othersRatio: 0.8 },
      { quota: 99, haircutRatio: 0.9, othersRatio: 0.9 }
    ]
  },
  availableCityDic: {
    1: true,
    10: true
  },
  availableDistrictDic: {
    5: true,
    6: true,
    115: true
  },
  passersbyCategoryDic: {
    haircutOrWash: '洗髮/剪髮班',
    dyeOrPerm: '染髮/燙髮班',
    haircareOrScalp: '護髮/頭皮班',
    bangTrim: '修瀏海班'
  },
  studioProductUnits: ['個', 'g', 'ml', '組', '瓶', '盒', '支', '包', '袋', '條', '罐']
}

import './bind-component'
import './bind-i18n'
import './bind-helper'
import './bind-hook'
import './bind-extension'

import './client-only'

if (process.browser) {
  const fetchVisitorKey = () => {
    const name = 'visitor-key'
    const value = getCookie(name)
    if (value) {
      setCookie(name, value)
      return value
    }
    const newKey = uuid()
    setCookie(name, newKey)
    return newKey
  }

  const fetchCampaignId = () => {
    const query = window.location.href.split('?')[1]
    if (!query) return
    const utm = query.split('utm_campaign=')[1]
    if (!utm) return
    const value = utm.split('&')[0]
    setCookie('visitor-campaign-id', value)
    return value
  }

  store.mkt.referrer = document.referrer
  store.mkt.key = fetchVisitorKey()
  store.mkt.campaignId = fetchCampaignId() || getCookie('visitor-campaign-id')
}
