const state = {
  discountLimits: [
    'noLimit',
    'depositMembers',
    'birthMonth',
    'firstTimeVisitors',
    'secondTimeVisitors',
    'visitWithinDays',
    'bookBefore',
    'bookAfter'
  ],
  bookingStateDic: {
    1: 'checked',
    2: 'finish',
    3: 'customerAbsence',
    4: 'cancel',
    5: 'customerCancel'
  }
}

export default function useBookingConfig() {
  return state
}
