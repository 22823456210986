import Close from 'components/icon/close'
import ShieldCheck from 'components/icon/shield-check'
import ChevronLeft from 'components/icon/chevron-left'
import ChevronRight from 'components/icon/chevron-right'
import ChevronDoubleRight from 'components/icon/chevron-double-right'
import Annotation from 'components/icon/annotation'
import PaperClip from 'components/icon/paper-clip'
import Check from 'components/icon/check'
import ArrowDown from 'components/icon/arrow-down'
import StarFilled from 'components/icon/star-filled'
import Plus from 'components/icon/plus'
import Heart from 'components/icon/heart'
import ArrowsExpand from 'components/icon/arrows-expand'
import Message from 'components/icon/message'
import Chat from 'components/icon/chat'
import Dot from 'components/icon/dot'
import Bell from 'components/icon/bell'
import Search from 'components/icon/search'
import User from 'components/icon/user'
import Logout from 'components/icon/logout'
import Switch from 'components/icon/switch'
import Calendar from 'components/icon/calendar'
import Card from 'components/icon/card'
import Dollar from 'components/icon/dollar'
import Setting from 'components/icon/setting'
import Studio from 'components/icon/studio'
import Account from 'components/icon/account'
import Download from 'components/icon/download'
import Records from 'components/icon/records'
import Detail from 'components/icon/detail'
import Edit from 'components/icon/edit'
import Language from 'components/icon/language'
import Login from 'components/icon/login'
import Join from 'components/icon/join'
import Statistic from 'components/icon/statistic'
import Cart from 'components/icon/cart'
import LockClosed from 'components/icon/lock-closed'

const dic = {
  locationF: ({ className }) => (
    <svg xmlns='http://www.w3.org/2000/svg' className={ className } viewBox='0 0 20 20' fill='currentColor'>
      <path fillRule='evenodd' d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z' clipRule='evenodd' />
    </svg>
  ),
  officeF: ({ className }) => (
    <svg xmlns='http://www.w3.org/2000/svg' className={ className } viewBox='0 0 20 20' fill='currentColor'>
      <path fillRule='evenodd' d='M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z' clipRule='evenodd' />
    </svg>
  ),
  clipboardList: ({ className }) => (
    <svg xmlns='http://www.w3.org/2000/svg' className={ className } fill='none' viewBox='0 0 24 24' stroke='currentColor'>
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={ 2 } d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01' />
    </svg>
  ),
  calendar: ({ className }) => (
    <svg xmlns='http://www.w3.org/2000/svg' className={ className } fill='none' viewBox='0 0 24 24' stroke='currentColor'>
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={ 2 } d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z' />
    </svg>
  ),
  phoneF: ({ className }) => (
    <svg xmlns='http://www.w3.org/2000/svg' className={ className } viewBox='0 0 20 20' fill='currentColor'>
      <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
    </svg>
  )
}

global.icon = (key, className) => {
  const Component = dic[key]
  return <Component className={ cn('w-5 h-5', className) } />
}

global.Icon = {
  Close,
  Download,
  Logout,
  Login,
  Heart,
  Switch,
  Calendar,
  Card,
  Dollar,
  Setting,
  Studio,
  Account,
  ChevronLeft,
  ChevronRight,
  ChevronDoubleRight,
  Annotation,
  PaperClip,
  Check,
  ShieldCheck,
  ArrowDown,
  ArrowsExpand,
  StarFilled,
  Message,
  Chat,
  Dot,
  Bell,
  Search,
  User,
  Plus,
  Records,
  Detail,
  Edit,
  Language,
  Join,
  Statistic,
  Cart,
  LockClosed
}
