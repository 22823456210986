const state = { visible: false }

export default function useSearch() {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('/hooks/search', uuid)

  const toggleVisible = () => {
    state.visible = !state.visible
    mutate()
    if (state.visible) mkt.startSearch()
  }

  return { state, toggleVisible }
}
