import { isEmpty } from 'lodash-es'

const state = {
  key: null,
  currentPath: '',
  visible: false,
  city: {
    title: 'common:filter.city',
    options: () => [],
    onChange: () => {
      state.city.id = cache.fetchCity().id
      state.district.value = ''
      state.district.id = null
    },
    value: ''
  },
  district: {
    title: 'common:filter.district',
    options: () => [],
    onChange: () => {
      state.district.id = cache.fetchDistrict().id
    },
    value: ''
  },
  hairLength: {
    title: 'common:filter.hairLength',
    options: () => [],
    value: ''
  },
  price: {
    title: 'common:filter.price',
    options: () => [],
    value: ''
  },
  service: {
    title: 'common:filter.service',
    options: () => [],
    value: ''
  }
}

const strategy = {
  style: {
    hideFilter: 'service',
    location: () => {
      const [, city, district] = store.ctx.router.query.params
      return { city, district }
    },
    locationChange: () => {
      const [id] = store.ctx.router.query.params
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const tagId = cache.fetchHairLength()
      h.path.go.style({
        id,
        city,
        district,
        force: true,
        tagId
      })
    },
    hairLengthChange: () => {
      const [id] = store.ctx.router.query.params
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const tagId = cache.fetchHairLength()
      h.path.go.style({
        id,
        city,
        district,
        force: true,
        tagId
      })
    }
  },
  stylists: {
    hideFilter: 'hairLength',
    location: () => {
      const [serviceCategory, city, district] = store.ctx.router.query.params || []
      return { serviceCategory: serviceCategory || 'haircut', city, district }
    },
    locationChange: () => {
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const tagId = cache.fetchHairLength()
      const category = cache.fetchService()
      h.path.go.stylists({
        city,
        district,
        tagId,
        category
      })
    },
    hairLengthChange: () => {
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const tagId = cache.fetchHairLength()
      const category = cache.fetchService()
      h.path.go.stylists({
        city,
        district,
        tagId,
        category
      })
    },
    serviceChange: () => {
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const tagId = cache.fetchHairLength()
      const category = cache.fetchService()
      h.path.go.stylists({
        city,
        district,
        tagId,
        category
      })
    }
  },
  'stylist-events': {
    hideFilter: 'hairLength',
    location: () => {
      const [serviceCategory, city, district] = store.ctx.router.query.params || []
      return { serviceCategory: serviceCategory || 'haircut', city, district }
    },
    locationChange: () => {
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const tagId = cache.fetchHairLength()
      const category = cache.fetchService()
      h.path.go.stylistEvents({
        category,
        city,
        district,
        force: true,
        tagId
      })
    },
    hairLengthChange: () => {
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const tagId = cache.fetchHairLength()
      const category = cache.fetchService()
      h.path.go.stylistEvents({
        category,
        city,
        district,
        force: true,
        tagId
      })
    },
    serviceChange: () => {
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const tagId = cache.fetchHairLength()
      const category = cache.fetchService()
      h.path.go.stylistEvents({
        category,
        city,
        district,
        tagId
      })
    }
  },
  'studio-events': {
    hideFilter: 'hairLength',
    location: () => {
      const [serviceCategory, city, district] = store.ctx.router.query.params || []
      return { serviceCategory: serviceCategory || 'haircut', city, district }
    },
    locationChange: () => {
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const serviceCategory = cache.fetchService()
      h.path.go.studioEvents({
        serviceCategory,
        city,
        district
      })
    },
    serviceChange: () => {
      const city = cache.fetchCity().key
      const district = cache.fetchDistrict().key
      const serviceCategory = cache.fetchService()
      h.path.go.studioEvents({
        serviceCategory,
        city,
        district,
        preventScroll: true
      })
    }
  }
}

const cache = {}

export default function useFilter() {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('/hooks/filter', uuid)
  const router = useRouter()
  const t = useI18n()

  const pathKey = router.pathname.split('/')[1]
  state.visible = strategy[pathKey]

  const checkPathLocation = () => {
    const currentPath = store.ctx.router.pathname.split('/')[1]
    state.currentPath = currentPath
    if (!state.key) return
    if (!strategy[currentPath]) return

    const setLocationInfo = () => {
      const { serviceCategory, city, district } = strategy[currentPath].location()

      state.service.value = ''
      store.filter.service = null
      if (serviceCategory) {
        state.service.value = serviceCategory
        store.filter.service = serviceCategory
      }

      if (!city) {
        store.filter.city = null
        store.filter.district = null
        state.city.id = null
        state.city.value = ''
        state.district.id = null
        state.district.value = ''
        mutate()
        return
      }
      const matchCity = cache.cities.find(c => c.key === city)
      state.city.id = matchCity.id
      state.city.value = matchCity.name
      store.filter.city = matchCity.key
      if (!district) {
        state.district.id = null
        state.district.value = ''
        store.filter.district = null
        return
      }
      const matchDistrict = cache.districts.find(d => d.cityName === matchCity.name && d.key === district)
      state.district.id = matchDistrict.id
      state.district.value = matchDistrict.name
      store.filter.district = matchDistrict.key
    }

    setLocationInfo()

    //state.service.value = ''
    state.hairLength.value = ''
    //store.filter.service = null
    store.filter.hairLength = null
    //const { tag } = store.ctx.router.query
    const { category, tag } = store.ctx.router.query
    if (category) {
      state.service.value = category
      store.filter.service = category
    }
    if (tag) {
      const tagId = parseInt(tag)
      state.hairLength.value = tagId
      store.filter.hairLength = tagId
    }
    state.key = uuid()
    mutate()
  }

  const init = async() => {
    const cities = await store.fetchCities()
    const districts = await store.fetchDistricts()

    cache.cities = cities
    cache.districts = districts

    state.key = uuid()
    checkPathLocation()

    cache.fetchCity = () => {
      const value = state.city.value
      if (!value) return {}
      cache.cityDic ||= cities.keyBy('name')
      return cache.cityDic[value]
    }

    cache.fetchDistrict = () => {
      const value = state.district.value
      if (!value) return {}
      const city = state.city.value
      cache.districtDic ||= {}
      cache.districtDic[city] ||= districts
        .filter(d => d.cityName === city)
        .keyBy('name')

      return cache.districtDic[city][value]
    }

    cache.fetchService = () => state.service.value

    cache.fetchHairLength = () => state.hairLength.value

    state.hairLength.options = () => {
      cache.hairLengthOptions ||= [
        { value: '', text: t('common:filter.options.hairLength.all') },
        { value: 4609, text: t('common:filter.options.hairLength.manShort') },
        { value: 3, text: t('common:filter.options.hairLength.womanShort') },
        { value: 4, text: t('common:filter.options.hairLength.womanMid') },
        { value: 4768, text: t('common:filter.options.hairLength.womanLong') },
        { value: 3224, text: t('common:filter.options.hairLength.bangsLong') },
        { value: 4818, text: t('common:filter.options.hairLength.bangsShort') }
      ]
      return cache.hairLengthOptions
    }

    state.price.options = () => {
      cache.priceOptions ||= [
        { value: '', text: t('common:filter.options.price.all') },
        { value: 1, text: t('common:filter.options.price.economical') },
        { value: 2, text: t('common:filter.options.price.highCP') },
        { value: 3, text: t('common:filter.options.price.highEnd') }
      ]
      return cache.priceOptions
    }

    state.service.options = () => {
      if (['stylists', 'stylist-events'].includes(state.currentPath)) {
        cache.serviceOptions ||= store.stylistEventFilterCategories.map(category => ({ value: category, text: t(`common:service.category.${category}`) }))
        return cache.serviceOptions
      }
      cache.serviceOptions ||= store.filterCategories.map(category => ({ value: category, text: t(`common:service.category.${category}`) }))
      return cache.serviceOptions
    }

    state.city.options = () => {
      cache.cityOptions ||= [
        { value: '', text: t('common:filter.options.selectCity') },
        ...cities.filter(({ id }) => store.availableCityDic[id])
          .map(({ name }) => ({ value: name, text: name }))
      ]
      return cache.cityOptions
    }

    state.district.options = () => {
      cache.district ||= {}
      const cityName = state.city.value
      cache.district[cityName] ||= [
        { value: '', text: t('common:filter.options.selectDistrict') },
        ...districts.filter(d => d.cityName === cityName)
          .filter(({ id }) => store.availableDistrictDic[id])
          .map(({ name }) => ({ value: name, text: name }))
      ]
      return cache.district[cityName]
    }
  }

  const getConditionParams = () => {
    const params = {}
    if (state.city.id) params.cityId = state.city.id
    if (state.district.id) params.districtId = state.district.id
    if (state.price.value) params.priceLevel = state.price.value
    if (state.service.value) params.serviceCategory = state.service.value
    if (isEmpty(params)) return
    return params
  }

  const getConditionTagIds = () => {
    if (!state.hairLength.value) return []
    return [state.hairLength.value]
  }

  const getConditionTagName = () => {
    let texts = []
    if (state.city.id) texts.push(state.city.value)
    if (state.district.id) texts.push(state.district.value)
    if (state.service.value) texts.push(t(`common:service.category.${state.service.value}`))
    if (!state.hairLength.value) return texts.join('、')
    const options = state.hairLength.options()
    const option = options.find(c => c.value === state.hairLength.value)
    if (option) texts.push(option.text)
    return texts.join('、')
  }

  const getLogParams = () => JSON.stringify({
    ...getConditionParams(),
    tagId: state.hairLength.value//,
    //service: state.service.value
  })

  const getPathLocations = () => {
    const city = cache.fetchCity().key
    const district = cache.fetchDistrict().key
    return { city, district }
  }

  const checkFilterVisible = key => strategy[pathKey].hideFilter !== key

  const setFilter = key => {
    const label = () => {
      if (state[key].value) return ''
      return t(state[key].title)
    }

    return ({
      label: label(),
      value: state[key].value,
      options: state[key].options(),
      onChange: value => {
        updateFilter({ key, value })
      }
    })
  }

  const updateFilter = ({ key, value }) => {
    state[key].value = value
    mkt.changeFilter(getLogParams())

    if (key === 'service') {
      strategy[pathKey].serviceChange()
      return
    }
    if (key === 'hairLength') {
      strategy[pathKey].hairLengthChange()
      return
    }
    if (state[key].onChange) {
      state[key].onChange()
      strategy[pathKey].locationChange()
      return
    }

    state.key = uuid()
    mutate()
  }

  return {
    key: state.key,
    visible: state.visible,
    setFilter,
    updateFilter,
    checkPathLocation,
    checkFilterVisible,
    init,
    getConditionParams,
    getConditionTagIds,
    getConditionTagName,
    getPathLocations
  }
}
