import i18next from 'i18next'

const ns = ['common', 'panel', 'error', 'page', 'seo']
const locales = ['zh-TW', 'en-US']

const resources = {}
locales.forEach(locale => {
  resources[locale] = {}
  ns.forEach(namespace => {
    resources[locale][namespace] = require(`locales/${locale}/${namespace}.json`)
  })
})

i18next.init({
  ns,
  defaultNS: 'common',
  fallbackLng: 'zh-TW',
  resources
})

global.i18n = i18next
