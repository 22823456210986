import { compact } from 'lodash-es'

Array.prototype.sortBy = function(callback) {
  return this.sort((a, b) => {
    const firstResult = callback(a)
    const secondResult = callback(b)
    if (!Array.isArray(firstResult)) {
      if (firstResult === secondResult) return 0
      return firstResult < secondResult ? 1 : -1
    }

    for (let i = 0; i < firstResult.length; i += 1) {
      if (firstResult[i] === secondResult[i]) continue
      return firstResult[i] < secondResult[i] ? 1 : -1
    }
    return 0
  })
}

Array.prototype.sum = function(callback) {
  callback ||= value => value
  return this.reduce((carry, next) => carry + callback(next), 0)
}

Array.prototype.keyBy = function(key) {
  return this.reduce((carry, next) => {
    carry[next[key]] = next
    return carry
  }, {})
}

Array.prototype.groupBy = function(key) {
  return this.reduce((carry, current) => {
    carry[current[key]] ||= []
    carry[current[key]].push(current)
    return carry
  }, {})
}

Array.prototype.trueDic = function() {
  return this.reduce((carry, next) => {
    carry[next] = true
    return carry
  }, {})
}

Array.prototype.uniq = function() {
  return [...new Set(this)]
}

Array.prototype.at = function(index) {
  if (index >= 0) return this[index]
  return this[this.length + index]
}

Array.prototype.first = function() {
  return this[0]
}

Array.prototype.last = function() {
  return this[this.length - 1]
}

Array.prototype.compact = function() {
  return compact(this)
}
