let init
export default async function firebase() {
  if (init) return
  init = true
  const modules = await Promise.all([
    import('firebase/app'),
    import('firebase/database'),
    import('firebase/storage'),
    import('firebase/auth')
  ])

  const app = modules[0].default
  const config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
  }
  app.initializeApp(config)
  app.db = app.database()

  store.plugin.firebase = app
  app.auth().onAuthStateChanged(function(user) {
    store.plugin.firebaseSignIn = !!user
  })
}
