import useForm from './form'
import useStatic from './static'
import useCtx from './ctx'
import useStoreFactory from './store-factory'
import useBodyScroll from './body-scroll'
import useSWR from 'swr'
import useCurrent from 'hooks/current'
import useBookingConfig from 'hooks/booking-config'
import usePhoneCode from 'hooks/phone-code'
import usePanel from 'hooks/panel'
import useI18n from 'hooks/i18n'
import useLoading from 'hooks/loading'
import usePaymentMethod from 'hooks/payment-method'
import useTagConfig from 'hooks/tag-config'
import { useRouter } from 'next/router'
import useTrackPageScroll from 'hooks/track-page-scroll'
import { useState, useEffect, useRef, memo, useMemo, useCallback } from 'react'

global.useCtx = useCtx
global.useBodyScroll = useBodyScroll
global.useStoreFactory = useStoreFactory
global.useStatic = useStatic
global.useBookingConfig = useBookingConfig
global.usePhoneCode = usePhoneCode
global.useSWR = useSWR
global.useRouter = useRouter
global.useState = useState
global.useRef = useRef
global.useEffect = useEffect
global.useCurrent = useCurrent
global.usePanel = usePanel
global.useI18n = useI18n
global.useLoading = useLoading
global.useForm = useForm
global.usePaymentMethod = usePaymentMethod
global.memo = memo
global.useMemo = useMemo
global.useCallback = useCallback
global.useTagConfig = useTagConfig
global.useTrackPageScroll = useTrackPageScroll
