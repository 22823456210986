const defaultField = {
  value: '',
  init: true,
  rule: '',
  error: ''
}

export default function initNewForm(...props) {
  const form = {}
  props.forEach(prop => {
    if (typeof prop === 'string') {
      form[prop] = { ...defaultField }
      return
    }
    Object.keys(prop).forEach(key => {
      form[key] = {
        ...defaultField,
        ...prop[key]
      }
      if (form[key].value !== undefined) return
      form[key].value = ''
    })
  })
  return form
}
