const base = to => ({
  root: () => to('/'),
  article: ({ slug }) => to(`/article/${slug}`),
  articles: () => to('/articles'),
  referral: () => to('/referral'),
  referralCode: ({ code }) => to(`/referral/${code}`),
  stylists: ({ category = 'haircut', city, district, tagId } = {}) => {
    const path = ['/stylists']
    path.push(category)
    if (city) path.push(city)
    if (district) path.push(district)
    let url = path.join('/')
    const queryParams = []
    if (tagId) queryParams.push(`tag=${tagId}`) //url = `${url}?tag=${tagId}`
    if (queryParams.length) url = `${url}?${queryParams.join('&')}`
    return to(url)
  },
  stylist: ({ id }) => to(`/stylist/${id}`),
  studios: () => to('/studios'),
  stylistEvents: ({ category = 'haircut', city, district, tagId } = {}) => {
    const path = ['/stylist-events']
    path.push(category)
    if (city) path.push(city)
    if (district) path.push(district)
    let url = path.join('/')
    //if (tagId) url = `${url}?tag=${tagId}`
    const queryParams = []
    if (tagId) queryParams.push(`tag=${tagId}`)
    if (queryParams.length) url = `${url}?${queryParams.join('&')}`
    return to(url)
  },
  studioEvents: ({ serviceCategory = 'haircut', city, district, preventScroll } = {}) => {
    const path = ['/studio-events']
    const scroll = !preventScroll
    path.push(serviceCategory)
    if (city) path.push(city)
    if (district) path.push(district)
    const url = path.join('/')
    return to(url, undefined, { scroll })
  },
  event: ({ slug }) => to(`/event/${slug}`),
  studio: ({ id }) => to(`/studio/${id}`),
  styles: (gender = '') => {
    if (!gender) return to('/styles')
    return to(`/styles/${gender}`)
  },
  style: ({ id, city, district, tagId, force }) => {
    const path = [`/style/${id}`]
    if (!force) {
      city = store.filter.city || city
      district = store.filter.district || district
    }
    if (city) path.push(city)
    if (district) path.push(district)
    let url = path.join('/')
    if (tagId) url = `${url}?tag=${tagId}`
    return to(url)
  },
  post: ({ id }) => to(`/post/${id}`),
  postPhoto: ({ id, size = 'medium' }) => cdn(`post/photo/${size}/${id}.jpg`),
  studioManage: params => to(`/studio/manage/${params.join('/')}`),
  howToPay: () => to('/referral/9390359'),
  terms: () => to('/terms'),
  eliteStylist: () => to('/elite-stylist'),
  eliteStylistRule: () => to('/elite-stylist-rule'),
  joinUs: () => to('/join-us'),
  joinUsApply: ({ studioId }) => to(`/join-us/apply/${studioId}`),
  manage: tab => to(`/manage/${tab}`),
  revenueAnalysis: () => to('/admin/revenue-analysis'),
  payouts: () => to('/admin/payouts'),
  payoutReceivers: () => to('/admin/payout-receivers'),
  certRequestPhoto: stylistId => cdn(`user/profession/${stylistId}.jpg`),
  wordPress: () => to('/wp-admin'),
  sitemaps: ({ type, page }) => to(`/sitemaps/v1/${type}/${page}`),
  apply: ({ slug }) => to(`/apply/${slug}`),
  applyClose: () => to('/apply/close'),
  applyMonthAnalysis: () => to('/apply/month-analysis'),
  course: ({ slug }) => to(`/course/${slug}`),
  applicationModelReviewAdmin: () => to('/feedback/result/apply-form/admin'),
  applicationModelReviews: ({ userId }) => to(`/feedback/result/apply-form/${userId}`),
  applyFormReview: ({ id }) => to(`/feedback/apply-form/${id}`),
  feedbackCourseResult: ({ id }) => to(`/feedback/result/course/${id}`),
  academy: ({ teacherId }) => {
    if (!teacherId) return to('/academy')
    return to(`/academy/teacher/${teacherId}`)
  },
  visitorCheckout: ({ bookingId, studioId }) => to(`/visitor-checkout/${bookingId}/${studioId}`)
})

const direct = (...params) => {
  store.autoClose.skipOnce = true
  store.autoClose.closePanels()
  store.ctx.router.push(...params)
}

const replace = dic => {
  const query = {
    ...store.ctx.router.query,
    ...dic
  }
  for (let key in dic) {
    if (dic[key] !== undefined) continue
    delete query[key]
  }
  store.autoClose.skipOnce = true
  store.ctx.router.replace({ query }, undefined, {
    shallow: true,
    scroll: false
  })

}

export const path = {
  domain: process.env.NEXT_PUBLIC_DOMAIN,
  ...base(path => path),
  full: base(path => process.env.NEXT_PUBLIC_DOMAIN + path),
  go: base((...params) => direct(...params)),
  direct,
  replace,
  bind: base(path => () => direct(path))
}
