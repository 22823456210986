const template = `
  query GetMinimumWebVersion($version: String, $deviceInfo: String) {
    getMinimumWebVersion(version: $version, deviceInfo: $deviceInfo)
  }
`

export default async function getMinimumVersion(version) {
  const deviceInfo = navigator?.userAgent
  const { getMinimumWebVersion } = await request(template, { version, deviceInfo })
  return getMinimumWebVersion
}
