import dayjs from 'dayjs'

dayjs.prototype.ymd = function() {
  return this.format('YYYY-MM-DD')
}

dayjs.prototype.ymdDash = function() {
  return this.format('YYYY/MM/DD')
}

dayjs.prototype.md = function() {
  return this.format('MM/DD')
}

dayjs.prototype.mdw = function() {
  return this.format('MM/DD (dd)')
}

dayjs.prototype.ymdw = function() {
  return this.format('YYYY/MM/DD (dd)')
}

dayjs.prototype.ymdwhm = function() {
  return this.format('YYYY/MM/DD (dd) HH:mm')
}

dayjs.prototype.yearMonth = function() {
  return this.format('YYYY-MM')
}

dayjs.prototype.hms = function() {
  return this.format('HH:mm:ss')
}

dayjs.prototype.hm = function() {
  return this.format('HH:mm')
}

dayjs.prototype.dateTime = function() {
  return this.format('YYYY-MM-DD HH:mm:ss')
}

dayjs.prototype.ymdhm = function() {
  return this.format('YYYY/MM/DD HH:mm')
}

dayjs.prototype.slot = function() {
  const hour = this.hour() * 2
  const minute = this.minute()
  return hour + Math.floor(minute / 30)
}

dayjs.prototype.atSlot = function(slot) {
  return dayjs(`${this.ymd()} ${slot.slotToTime()}`)
}
